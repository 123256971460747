import products from './products'

export default [
  {
    name: 'Продукт',
    type: 'selectOneProduct',
    productIds: [products[0].id, products[1].id, products[2].id],
    productPosition: 0,
  },
  {
    name: 'Клиент',
    type: 'client',
  },
]
