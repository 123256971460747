import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Модуль Продукты - единая база документов по всем продуктам и услугам с быстрым доступом для обеспечения информированности продавцов',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Ключевые функции раздела:Вся необходимая информация по продуктам и тарифам в одном месте Централизованное обновление Предварительный просмотр Регулирование прав доступа',
    },
  },
]
