import safeGet from 'common/modules/safe-get'
import api from './api'
import naming from './naming'
import constructor from './constructor'

const config = {
  api,
  colors: {
    main: '#5D61FC',
  },
  entryVideoUrl: null,
  authorizationLayout: {
    clientLink: 'https://primaverahq.com/',
  },
  jivosite: {
    widgetId: 'WKYRseSWUq',
  },
  constructor,
  naming,
}

export default safeGet.bind(null, config)
