import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, что касается времени на внедрение продукта: в случае покупки всех модулей, в среднем это займет от 2 до 3 месяцев. Однако, внедрение может занять и 1 неделю, если Вам нужна только очень ограниченная часть функционала: например, только модуль «Новости».',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
]
