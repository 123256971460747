import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, я бы хотел задать Вам несколько более точечных вопросов по работе отдела продаж. Либо можем сразу перейти к представлению продукта. Как Вам удобнее?',
    },
  },
  {
    type: itemTypes.actions,
    value: [
      {
        link_id: '4.2',
        text: 'Вопросы об отделе',
      },
      {
        link_id: '5',
        text: 'Сразу к представлению продукта',
      },
      {
        link_id: '11.2',
        text: 'Не интересно',
      },
    ],
  },
]
