import card_1 from './cards/card_1'
import card_2 from './cards/card_2'
import card_3 from './cards/card_3'
import card_4_1 from './cards/card_4_1'
import card_4_2 from './cards/card_4_2'
import card_5 from './cards/card_5'
import card_6 from './cards/card_6'
import card_6_1 from './cards/card_6_1'
import card_6_2 from './cards/card_6_2'
import card_6_3 from './cards/card_6_3'
import card_6_4 from './cards/card_6_4'
import card_6_5 from './cards/card_6_5'
import card_6_6 from './cards/card_6_6'
import card_7 from './cards/card_7'
import card_8_1 from './cards/card_8_1'
import card_8_2 from './cards/card_8_2'
import card_9 from './cards/card_9'
import card_10 from './cards/card_10'
import card_10_1 from './cards/card_10_1'
import card_10_2 from './cards/card_10_2'
import card_10_3 from './cards/card_10_3'
import card_10_4 from './cards/card_10_4'
import card_10_5 from './cards/card_10_5'
import card_10_6 from './cards/card_10_6'
import card_11_1 from './cards/card_11_1'
import card_11_2 from './cards/card_11_2'
import card_11_3 from './cards/card_11_3'

export default [
  {
    id: '1',
    name: 'Вступление',
    content: card_1,
    subCards: null,
  },
  {
    id: '2',
    name: 'Выяснение интереса',
    content: card_2,
    subCards: null,
  },
  {
    id: '3',
    name: 'Определение релевантности',
    content: card_3,
    subCards: null,
  },
  {
    id: '4',
    name: 'Изучение проблем',
    content: null,
    subCards: [
      {
        id: '4.1',
        name: 'Развилка разговора',
        content: card_4_1,
        subCards: null,
      },
      {
        id: '4.2',
        name: 'Вопросы об отделе продаж',
        content: card_4_2,
        subCards: null,
      },
    ],
  },
  {
    id: '5',
    name: 'Представление Октаплана',
    content: card_5,
    subCards: null,
  },
  {
    id: '6',
    name: 'Модули Октаплана',
    content: card_6,
    subCards: [
      {
        id: '6.1',
        name: 'Скрипты продаж',
        content: card_6_1,
        subCards: null,
      },
      {
        id: '6.2',
        name: 'Коммерческие предложения',
        content: card_6_2,
        subCards: null,
      },
      {
        id: '6.3',
        name: 'Продукты',
        content: card_6_3,
        subCards: null,
      },
      {
        id: '6.4',
        name: 'Обучение',
        content: card_6_4,
        subCards: null,
      },
      {
        id: '6.5',
        name: 'Новости',
        content: card_6_5,
        subCards: null,
      },
      {
        id: '6.6',
        name: 'Поддержка продаж',
        content: card_6_6,
        subCards: null,
      },
    ],
  },
  {
    id: '7',
    name: 'Ценообразование',
    content: card_7,
    subCards: null,
  },
  {
    id: '8',
    name: 'Внедрение продукта',
    content: null,
    subCards: [
      {
        id: '8.1',
        name: 'Время внедрения',
        content: card_8_1,
        subCards: null,
      },
      {
        id: '8.2',
        name: 'Этапы внедрения',
        content: card_8_2,
        subCards: null,
      },
    ],
  },
  {
    id: '9',
    name: 'Сравнение с конкурентами',
    content: card_9,
    subCards: null,
  },
  {
    id: '10',
    name: 'Оформление сделки',
    content: card_10,
    subCards: [
      {
        id: '10.1',
        name: 'Модули и число лицензий',
        content: card_10_1,
        subCards: null,
      },
      {
        id: '10.2',
        name: 'Вводные для скриптов продаж',
        content: card_10_2,
        subCards: null,
      },
      {
        id: '10.3',
        name: 'Вводные для КП',
        content: card_10_3,
        subCards: null,
      },
      {
        id: '10.4',
        name: 'Вводные для продуктов',
        content: card_10_4,
        subCards: null,
      },
      {
        id: '10.5',
        name: 'Вводные для обучения',
        content: card_10_5,
        subCards: null,
      },
      {
        id: '10.6',
        name: 'Продолжение обсуждения',
        content: card_10_6,
        subCards: null,
      },
    ],
  },
  {
    id: '11',
    name: 'Отказ',
    content: null,
    subCards: [
      {
        id: '11.1',
        name: 'Не релевантно',
        content: card_11_1,
        subCards: null,
      },
      {
        id: '11.2',
        name: 'Не интересно в принципе',
        content: card_11_2,
        subCards: null,
      },
      {
        id: '11.3',
        name: 'Не интересно сейчас',
        content: card_11_3,
        subCards: null,
      },
      {
        id: '11.4',
        name: 'Звонок не во время',
        content: card_11_1,
        subCards: null,
      },
    ],
  },
]
