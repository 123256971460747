import call from './call'
import visit from './visit'

export default [
  {
    code: 'calls',
    name: 'Звонок',
    cards: call,
  },
  {
    code: 'visit',
    name: 'Визит к клиенту',
    cards: visit,
  },
]
