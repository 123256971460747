import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, спасибо за уделенное время. Если возникнут какие-либо вопросы, то звоните или пишите. Я с радостью отвечу Вам. То же самое касается материалов. Если хотите взглянуть на дополнительные материалы, то я Вам вышлю на почту.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
]
