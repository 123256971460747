export default [
  {
    id: 'license-amount',
    inputType: 'counter',
    visibility: 1,
    name: 'Количество лицензий',
    value: 0,
  },
  {
    id: 'sales-scripts-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Скрипты продаж',
    price: {
      monthly: {
        value: 1,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'commercial-proposals-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Коммерческое предложение',
    price: {
      monthly: {
        value: 2,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'products-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Продукты',
    price: {
      monthly: {
        value: 3,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'adaptation-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Обучение',
    price: {
      monthly: {
        value: 4,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'news-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Новости',
    price: {
      monthly: {
        value: 5,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'support-checkbox',
    inputType: 'checkbox',
    visibility: 1,
    name: 'Поддержка продаж',
    price: {
      monthly: {
        value: 6,
        caption: 'руб./мес.',
      },
    },
  },

  {
    id: 'sales-scripts-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Скрипты продаж',
    price: {
      monthly: {
        value: 1,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'commercial-proposals-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Коммерческое предложение',
    price: {
      monthly: {
        value: 2,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'products-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Продукты',
    price: {
      monthly: {
        value: 3,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'adaptation-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Обучение',
    price: {
      monthly: {
        value: 4,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'news-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Новости',
    price: {
      monthly: {
        value: 5,
        caption: 'руб./мес.',
      },
    },
  },
  {
    id: 'support-counter',
    inputType: 'counter',
    visibility: 3,
    name: 'Поддержка продаж',
    price: {
      monthly: {
        value: 6,
        caption: 'руб./мес.',
      },
    },
  },
]
