import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, давайте я расскажу Вам из каких модулей состоит наш продукт и какой конкретный результат каждый модуль может принести менеджеру по продажам.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.text,
    value:
      'Либо выбираем инетерсующий модуль, либо идем по порядку (начиная со скриптов продаж)',
  },
  {
    type: itemTypes.actions,
    value: [
      {
        link_id: '6.1',
        text: 'Скрипты продаж',
      },
      {
        link_id: '6.2',
        text: 'Коммерческие предложения',
      },
      {
        link_id: '6.3',
        text: 'Продукты',
      },
      {
        link_id: '6.4',
        text: 'Обучение',
      },
      {
        link_id: '6.5',
        text: 'Новости',
      },
      {
        link_id: '6.6',
        text: 'Поддержка продаж',
      },
    ],
  },
]
