let url = '/api'

if (process.env.NODE_ENV === 'development') {
  url = '//oktaplan.primaverahq.com/api'
}

let config = {
  url,
}

export default config
