import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, давайте теперь расскажу Вам про стоимость нашего решения. Ценообразования «Октаплана» устроено таким образом, что Вы платите только за те модули продукта, которые Вам нужны.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Вы платите за лицензию на одного человека каждый месяц. При этом цена лицензий за модули не одинаковая. Модули «Скрипты продаж» и «Коммерческие предложения» стоят по 700 рублей на человека в месяц. Модули «Продукты» и «Обучение» - 500 рублей на человека в месяц. Модули «Новости» и «Поддержка продаж» - 200 рублей.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'В стоимость лицензии входят следующие элементы: техническая поддержку модуля, внесение корректировок в материалы, обновление раздела «Новости» раз в неделю, real-time аналитика в разделе «Коммерческие предложения» для менеджеров по продажам, выгрузка аналитики для руководства раз в неделю по разделам «Коммерческие предложения» и «Обучение»',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Для первоначальной установки продукта необходимо будет внести разовый интеграционный платеж, который мы рассчитаем на основе вашего выбора модулей и объема информации, которым необходимо наполнить каждый из модулей. Стоимость интеграционного платежа начинается с 50 тысяч рублей.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
]
