export default {
  iframe: {
    vpbx: 'prima/vpbx/v1',
  },
  invoice: {
    totals: {
      annually: ({ monthly, annually }) => monthly * 12 + annually,
    },
  },
}
