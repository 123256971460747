import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Конструктор КП с актуальными тарифами для автоматического создания персонализированных презентаций',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Ключевые функции раздела:Калькулятор с актуальными тарифами по всем продуктам Автоматическое создание презентаций Уведомления о прочтении КП Real-time аналитика просмотров КП',
    },
  },
]
