import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Давайте перейдем непосредственно к демонстрации продукта. Так как продукт кастомизирован под каждого клиента, то я продемонстрирую Вам «Октаплан», созданный для нашей компании - «Примавера»',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.text,
    value: 'Приступить к демонстрации продукта',
  },
]
