import { changeOption } from 'constructors/ducks/options/actions'

export const mutations = (store, action) => {
  if (
    action.payload.optionId !== 'general:oktaplan:sales-scripts-checkbox' &&
    action.payload.optionId !==
      'general:oktaplan:commercial-proposals-checkbox' &&
    action.payload.optionId !== 'general:oktaplan:products-checkbox' &&
    action.payload.optionId !== 'general:oktaplan:adaptation-checkbox' &&
    action.payload.optionId !== 'general:oktaplan:news-checkbox' &&
    action.payload.optionId !== 'general:oktaplan:support-checkbox'
  )
    return

  const targetOptionId = action.payload.optionId.replace(
    '-checkbox',
    '-counter'
  )

  store.dispatch(
    changeOption({
      optionId: targetOptionId,
      content: {
        visibility: action.payload.value ? 2 : 3,
      },
    })
  )
}

export default mutations
