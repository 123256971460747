import { changeOptionValue } from 'constructors/ducks/values/actions'

export const mutations = (store, action) => {
  if (action.payload.optionId !== 'general:oktaplan:license-amount') return

  ;[
    'sales-scripts-counter',
    'commercial-proposals-counter',
    'products-counter',
    'adaptation-counter',
    'news-counter',
    'support-counter',
  ].map(option => store.dispatch(setValue(option, action.payload.value)))
}

const setValue = (option, value) => dispatch => {
  dispatch(
    changeOptionValue({
      optionId: `general:oktaplan:${option}`,
      value,
    })
  )
}

export default mutations
