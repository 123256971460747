import { changeOptionValue } from 'constructors/ducks/values/actions'
import licenseAmount from './license-amount'
import optionsVisibility from './options-visibility'

const generalConstructorMutationsMiddleware = store => next => action => {
  if (action.type !== changeOptionValue.getType()) {
    return next(action)
  }

  next(action)
  licenseAmount(store, action)
  optionsVisibility(store, action)

  return
}

export default generalConstructorMutationsMiddleware
