import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Я вас понял. Я могу отправить Вам по почте свои контактные данные, если вдруг у Вас появится интерес. Выслать Вам данные на почту?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text:
                'Не могли бы Вы продиктовать мне свою электронную почту или номер телефона, чтобы я отправил Вам свои контакты? Как Вам удобнее.',
            },
          },
          {
            type: itemTypes.speech,
            value: {
              who: speechK,
              text: '...',
            },
          },
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text: 'Спасибо за разговор, {{clientName|<ИО>}}. До свидания!',
            },
          },
        ],
      },
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text:
                'Хорошо, спасибо за разговор, {{clientName|<ИО>}}. До свидания!',
            },
          },
        ],
      },
    ],
  },
]
