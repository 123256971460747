const id = 'emny'

export default {
  id,
  code: id,
  name: 'HR система Emny',
  shortName: 'Emny',
  vat: false,
  options: [],
}
