import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Скрипты продаж: Данный модуль создает интерактивные текстовые блоки, которые помогают продавцам составить разговор с клиентам. «Скрипты продаж» помогают продавцам избежать ошибок во время общения с потенциальными покупателями.',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Ключевые функции раздела: Изменение текста в соответствии с ответами клиента Простая и быстрая навигация по скрипту Централизованное обновление',
    },
  },
]
