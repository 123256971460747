export default {
  kpPreview: {
    emailTemplate: {
      text: () =>
        'По ссылке ниже вы можете найти персональное предложение от ООО «Примавера»',
    },
    versionsSelect: {
      whole: 'Полная версия',
      brief: 'Сокращенная версия',
    },
  },
}
