import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Внедрение продукта мы подразделяем на 4 этапа:Согласование финального вида продукта – 1 неделя. Мы согласовываем с Вами дизайн и определяем наполнение модулей Наполнение модулей материалами – 4-6 недель. На данном этапе мы подготавливаем Ваши материалы и загружаем их в программу Пилотирование – 1-2 недели. На этапе пилотирования мы тестируем «Октаплан» на ограниченном числе пользователей и собираем обратную связь Полноценный запуск – 1-2 недели. Финальный этап подразумевает доработку материалов на основе обратной связи и полноценное подключение всех пользователей',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
]
