import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'База релевантных новостей из различных источников для расширения знаний об индустрии',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Ключевые функции раздела:Все актуальные новости индустрии и компании собраны в одном месте Быстрый доступ к источнику новости Просмотр новостей внутри системы Сохранение новостей в случае удаления из источника',
    },
  },
]
