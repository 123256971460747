import products from './products'
import stages from './stages'

const id = 'general'

export default {
  id,
  name: 'Наши продукты',
  products,
  stages,
}
