import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Говоря о других решениях на рынке, на данный момент сопоставимого результата Вы не сможете добиться текущими решениями. Для наглядности, я могу показать Вам презентацию по каждому конкуренту с анализом функционала и стоимости. Интересно будет взглянуть?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.text,
            value:
              'Высылаем презентацию со сравнительным анализом конкурентов на почту или показываем презентацию с компьютера лично',
          },
        ],
      },
      {
        title: 'Если «Нет»',
        value: [
          {
            type: itemTypes.text,
            value: 'Оформление сделки',
          },
        ],
      },
    ],
  },
]
