import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, мы можем приступить к оценке стоимости «Октаплана» для вашей компании. Для этого мне понадобится от Вас некоторая информация о компании.',
    },
  },
  {
    type: itemTypes.actions,
    value: [
      {
        link_id: '10.1',
        text: 'Хорошо',
      },
      {
        link_id: '10.6',
        text: 'Нет',
      },
    ],
  },
]
