import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Хорошо, {{clientName|<ИО>}}, давайте я расскажу Вам подробнее о нашем решении. Мы предлагаем централизованную систему для обработки лидов, которая: Автоматизирует создание персонализированных коммерческих предложений Увеличивает скорость обновления информации о продуктах и тарифах Сокращает время на обучение менеджеров и проверку их знаний',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Такие результаты достигаются за счет устранения ключевых проблем продаж:Разрозненность хранения информации решается за счет сбора всех актуальных материалов в одном месте Неустойчивое качество продаж из-за несоблюдения стандартов сотрудниками решается за счет формирования единых интерактивных скриптов, автоматизации создания коммерческих предложений и введения единой системы обучения и оценки знаний Медленная реакция на рыночные изменения решается за счет предоставления необходимой аналитики по продажам в реальном времени',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Сам продукт состоит из 6 автономных модулей, каждый из которых приносит конкретный результат менеджеру по продажам. Могу более подробно рассказать Вам про каждый модуль. Хотите послушать или перейдем к демонстрации продукта?',
    },
  },
  {
    type: itemTypes.actions,
    value: [
      {
        link_id: '4',
        text: 'Обсудить модули сейчас',
      },
      {
        link_id: '2',
        text: 'Приступить к демонстрации',
      },
    ],
  },
]
