import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, спасибо за уделенное время. Не могли бы Вы подсказать, почему Вам не интересен продукт?',
    },
  },
  {
    type: itemTypes.actions,
    value: [
      {
        link_id: '9',
        text: 'Уже есть решение',
      },
      {
        link_id: '11.3',
        text: 'Не проблема для клиента',
      },
      {
        link_id: '9',
        text: 'Дорого',
      },
      {
        link_id: '11.3',
        text: 'Другое',
      },
    ],
  },
]
