const id = 'staply'

export default {
  id,
  code: id,
  name: 'Мессенджер Staply',
  shortName: 'Staply',
  vat: false,
  options: [],
}
