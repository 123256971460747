import {
  speechM,
  speechK,
  itemTypes,
  inputTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.input,
    value: {
      type: inputTypes.text,
      keyPath: 'clientName',
      who: speechK,
      placeholder: 'Имя Отчество клиента',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Добрый день, {{clientName|<ИО>}}. Рад, что у нас получилось встретиться лично. Вы посмотрели материалы, которые я Вам направлял по нашему продукту?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text: 'У Вас остались какие либо вопросы по продукту?',
            },
          },
          {
            type: itemTypes.speech,
            value: {
              who: speechK,
              text: '...',
            },
          },
          {
            type: itemTypes.text,
            value: 'Если остались вопросы, то ответить',
          },
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text:
                'Можем еще раз коротко пройтись по основным положениям «Октаплана» или сразу перейдем к демонстрации продукта. Как удобнее?',
            },
          },
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '3',
                text: 'Основные положения',
              },
              {
                link_id: '2',
                text: 'Демонстрация продукта',
              },
            ],
          },
        ],
      },
      {
        title: 'Если «Нет»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '2',
                text: 'Демонстрация продукта',
              },
            ],
          },
        ],
      },
    ],
  },
]
