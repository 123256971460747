import options from './options'
const id = 'oktaplan'

export default {
  id,
  code: id,
  name: 'Система продаж Октаплан',
  shortName: 'Октаплан',
  vat: false,
  options,
}
