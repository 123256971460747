import {
  speechM,
  speechK,
  itemTypes,
  inputTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Добрый день, меня зовут {{managerName|<Ваше имя>}} и я представляю компанию «Примавера». Мы занимаемся разработкой и внедрением ИТ решений для крупного бизнеса. Подскажите, как я могу к Вам обращаться?',
    },
  },
  {
    type: itemTypes.input,
    value: {
      type: inputTypes.text,
      keyPath: 'clientName',
      who: speechK,
      placeholder: 'Имя Отчество клиента',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Очень приятно, {{clientName|<ИО>}}. Удобно ли Вам сейчас разговаривать?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '2',
                text: 'Выяснение интереса',
              },
            ],
          },
        ],
      },
      {
        title: 'Если «Нет»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '11.4',
                text: 'Звонок не во время',
              },
            ],
          },
        ],
      },
    ],
  },
]
