import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text: 'Информация о продуктах и тарифах хранится у вас централизованно?',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Существует ли единая программа обучения и проверки знаний для продавцов? Как быстро продавцы успевают освоить программу?',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Используете ли вы real-time аналитику продаж, чтобы своевременно реагировать на рыночные изменения?',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Существуют ли еще проблемы в отделе продаж, которые Вас беспокоят?',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.text,
    value: 'Записываем проблемы, о которых не успели поговорить',
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если Октаплан подходит для решения проблем',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '5',
                text: 'Можем решить проблемы',
              },
            ],
          },
        ],
      },
      {
        title: 'Если Октаплан не поможет решить проблемы',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '11.1',
                text: 'Не можем решить проблемы',
              },
            ],
          },
        ],
      },
    ],
  },
]
