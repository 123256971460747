import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Тогда, {{clientName|<ИО>}}, если у Вас еще остались вопросы, то можем обсудить. Если нет, то предлагаю перейти к демонстрации продукта.',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если есть вопросы',
        value: [
          {
            type: itemTypes.text,
            value: 'Отвечаем на имеющиеся вопросы',
          },
        ],
      },
      {
        title: 'Если нет вопросов',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '2',
                text: 'Демонстрация продукта',
              },
            ],
          },
        ],
      },
    ],
  },
]
