import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Отлично, {{clientName|<ИО>}}. Давайте для начала я задам Вам несколько вопросов, чтобы определить релевантность нашего продукта именно для Вашей компании, чтобы зря Ваше время не тратить?',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text: 'Подскажите, использует ли Ваша компания прямые продажи?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.speech,
            value: {
              who: speechM,
              text: 'Сколько человек работает в отделе прямых продаж?',
            },
          },
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '11.1',
                text: 'До 100',
              },
              {
                link_id: '4.1',
                text: 'Больше 100',
              },
            ],
          },
        ],
      },
      {
        title: 'Если «Нет»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '11.1',
                text: 'Не релевантно',
              },
            ],
          },
        ],
      },
    ],
  },
]
