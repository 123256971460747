import card_1 from './cards/card_1'
import card_2 from './cards/card_2'
import card_3 from './cards/card_3'
import card_4 from './cards/card_4'
import card_4_1 from './cards/card_4_1'
import card_4_2 from './cards/card_4_2'
import card_4_3 from './cards/card_4_3'
import card_4_4 from './cards/card_4_4'
import card_4_5 from './cards/card_4_5'
import card_4_6 from './cards/card_4_6'
import card_5 from './cards/card_5'
import card_6_1 from './cards/card_6_1'
import card_6_2 from './cards/card_6_2'
import card_7 from './cards/card_7'
import card_8 from './cards/card_8'
import card_9 from './cards/card_9'

export default [
  {
    id: '1',
    name: 'Вступление',
    content: card_1,
    subCards: null,
  },
  {
    id: '2',
    name: 'Демонстрация',
    content: card_2,
    subCards: null,
  },
  {
    id: '3',
    name: 'Представление решения',
    content: card_3,
    subCards: null,
  },
  {
    id: '4',
    name: 'Описание модулей',
    content: card_4,
    subCards: [
      {
        id: '4.1',
        name: 'Скрипты продаж',
        content: card_4_1,
        subCards: null,
      },
      {
        id: '4.2',
        name: 'Коммерческие предложения',
        content: card_4_2,
        subCards: null,
      },
      {
        id: '4.3',
        name: 'Продукты',
        content: card_4_3,
        subCards: null,
      },
      {
        id: '4.4',
        name: 'Обучение',
        content: card_4_4,
        subCards: null,
      },
      {
        id: '4.5',
        name: 'Новости',
        content: card_4_5,
        subCards: null,
      },
      {
        id: '4.6',
        name: 'Поддержка продаж',
        content: card_4_6,
        subCards: null,
      },
    ],
  },
  {
    id: '5',
    name: 'Ценообразование',
    content: card_5,
    subCards: null,
  },
  {
    id: '6',
    name: 'Внедрение',
    content: null,
    subCards: [
      {
        id: '6.1',
        name: 'Сроки внедрения',
        content: card_6_1,
        subCards: null,
      },
      {
        id: '6.2',
        name: 'Этапы внедрения',
        content: card_6_2,
        subCards: null,
      },
    ],
  },
  {
    id: '7',
    name: 'Оставшиеся вопросы',
    content: card_7,
    subCards: null,
  },
  {
    id: '8',
    name: 'Конкуренты',
    content: card_8,
    subCards: null,
  },
  {
    id: '9',
    name: 'Конец разговора',
    content: card_9,
    subCards: null,
  },
]
