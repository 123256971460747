export const speechM = 'М'
export const speechK = 'К'

export const speech = {
  M: 'М',
  K: 'К',
}

export const itemTypes = {
  text: 'text',
  speech: 'speech',
  spoiler: 'spoiler',
  choice: 'choice',
  actions: 'actions',
  calculationMapping: 'calculation-mapping',
  input: 'input',
  fork: 'fork',
}

export const inputTypes = {
  text: 'text',
}

export const calculationNames = {
  computers: 'computers',
  usage: 'usage',
}

export const tariffs = {
  S: 'Пакет S (10 Мбит/с)',
  M: 'Пакет M (30 Мбит/с)',
  L: 'Пакет L (50 Мбит/с)',
  XL: 'Пакет XL (100 Мбит/с)',
}
