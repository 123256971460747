import {
  speechM,
  speechK,
  itemTypes,
} from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Модуль Обучение - это учебные курсы для сотрудников с аналитикой и отслеживанием процесса обучения для быстрой адаптации и повышения квалификации',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechK,
      text: '...',
    },
  },
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        'Ключевые функции раздела:Структурированный каталог курсов Аналитика прохождения программы обучения Напоминания сотрудникам Форма обратной связи для сотрудников по материалам',
    },
  },
]
