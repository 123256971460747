import { speechM, itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

export default [
  {
    type: itemTypes.speech,
    value: {
      who: speechM,
      text:
        '{{clientName|<ИО>}}, на сегодняшний день, у нас есть цифровое решение, позволяющее улучшить ключевые показатели отдела продаж. Интересно будет обсудить в течении 10-15 минут?',
    },
  },
  {
    type: itemTypes.fork,
    value: [
      {
        title: 'Если «Да»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '2',
                text: 'Определение интереса',
              },
            ],
          },
        ],
      },
      {
        title: 'Если «Нет»',
        value: [
          {
            type: itemTypes.actions,
            value: [
              {
                link_id: '11.2',
                text: 'Не интересно',
              },
            ],
          },
        ],
      },
    ],
  },
]
